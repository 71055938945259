import { createSlice } from '@reduxjs/toolkit'
import { removeCookie } from 'utils/cookie'

export interface IUserState {
  code?: string
  role?: string
  id?: number
  isMessageUnread?: string
  isNoticeUnread?: string
}

const initialState: IUserState = {
  role: undefined,
  id: undefined,
  isMessageUnread: undefined,
  isNoticeUnread: undefined
}

const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    changeRole: (state, action) => {
      state.role = action.payload
    },
    storeUser: (state, action) => {
      localStorage.setItem('role', action.payload.role)
      return action.payload
    },
    setIdCurrent: (state, action) => {
      state.id = action.payload
    },
    setIsMessageUnread: (state, action) => {
      state.isMessageUnread = action.payload
    },
    setIsNoticeUnread: (state, action) => {
      state.isNoticeUnread = action.payload
    },
    clearUser: () => {
      removeCookie('access_token')
      localStorage.removeItem('role')
      return initialState
    }
  }
})

export default userSlice
