import { lazy } from 'react'

const SiteBussiness = lazy(async () => await import('./pages/siteBussiness/index'))
const Login = lazy(async () => await import('./pages/login/index'))
const ForecastReceived = lazy(async () => await import('./pages/forecastReceived/index'))
const ECSiteBusiness = lazy(async () => await import('./pages/ecSiteBusiness'))
const CallWorkForm = lazy(async () => await import('./pages/BToC/userb2c/callwork/index'))
const CallWork = lazy(async () => await import('./pages/BToC/callWork/index'))
const Dashboard = lazy(async () => await import('./pages/BToC/user/dashboard/index'))
const OperatorPerformance = lazy(async () => await import('./pages/BToC/operatorPerformance/index'))
const ProductsAcquired = lazy(async () => await import('./pages/BToC/productsAcquired/index'))
const HistoryReportDashboard = lazy(async () => await import('./pages/BToC/user/historyReport/index'))
const PageNotFound = lazy(async () => await import('./pages/PageNotFound'))
const MultiStaffDashboard = lazy(async () => await import('./pages/BToC/staffDashboard/MultiStaffDashboard'))
const CallBusinessList = lazy(async () => await import('./pages/BToC/callBusinessList/index'))
const routes = [
  {
    path: '/site-business-management',
    element: SiteBussiness,
    isProtect: true,
    role: ['all', 'b2b']
  },
  {
    path: '/login',
    element: Login
  },
  {
    path: '/forecast-received',
    element: ForecastReceived,
    isProtect: true,
    role: ['all', 'b2b']
  },
  {
    path: '/ec-site-business',
    element: ECSiteBusiness,
    isProtect: true,
    role: ['all', 'b2b']
  },
  {
    path: '/call-work-form',
    element: CallWorkForm,
    isProtect: true,
    role: ['all', 'b2c-user']
  },
  {
    path: '/call-work-detail/:id',
    element: CallWork,
    isProtect: true,
    role: ['all', 'b2c-user']
  },
  {
    path: '/dashboard',
    element: Dashboard,
    isProtect: true,
    role: ['all', 'b2c-admin']
  },
  {
    path: '/dashboard-history-report',
    element: HistoryReportDashboard,
    isProtect: true,
    role: ['all', 'b2c-admin']
  },
  {
    path: '/dashboard-operator-performance',
    element: OperatorPerformance,
    isProtect: true,
    role: ['all', 'b2c-admin']
  },
  {
    path: '/dashboard-products-acquired',
    element: ProductsAcquired,
    isProtect: true,
    role: ['all', 'b2c-admin']
  },
  {
    path: '*',
    element: PageNotFound,
    isProtect: true
  },
  {
    path: '/multi-staff-dashboard',
    element: MultiStaffDashboard,
    isProtect: true,
    role: ['all', 'b2c-user']
  },
  {
    path: '/',
    element: MultiStaffDashboard,
    isProtect: true,
    role: ['all', 'b2c-user']
  },
  {
    path: '/call-work-business-list',
    element: CallBusinessList,
    isProtect: true,
    role: ['all', 'b2c-user']
  }
]

export default routes
