import axios from './axios'

const grantType = 'password'

const REACT_APP_CLIENT_ID = process.env.REACT_APP_CLIENT_ID
const REACT_APP_CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET
const scope = 'me table_read value_read view_read value_write'

class AuthApi {
  loginWithToken = (username: any, password: any) => {
    const url = '/oauth/token'
    const data = {
      username,
      password,
      grant_type: grantType,
      client_id: REACT_APP_CLIENT_ID,
      client_secret: REACT_APP_CLIENT_SECRET,
      scope
    }
    return axios.post(url, data)
  }

  getUser = (idToken: any) => {
    const url = '/api/me'
    const config: any = {
      headers: { Authorization: `Bearer ${idToken}` }
    }
    return axios.get(url, config)
  }
}

const authApi = new AuthApi()
export default authApi
