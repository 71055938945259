/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import React from 'react'
import { Layout, Menu } from 'antd'
import styled from '@emotion/styled'
import SideIconBlack from 'assets/header/side-icon-black.svg'
import SideIconWhite from 'assets/header/side-icon-white.svg'
import { Link, useLocation } from 'react-router-dom'
import './layout.scss'
import SubMenu from 'antd/es/menu/SubMenu'
import queryString from 'query-string'

const { Sider } = Layout

const listMenu = [
  {
    title: 'マッチングサイト業務',
    path: '/site-business-management'
  },
  {
    title: '受注案件予実',
    path: '/forecast-received'
  },
  {
    title: 'ECサイト業務 ',
    path: '/ec-site-business'
  }
]

const listMenuB2CAdmin = [
  {
    title: 'コール業務',
    path: '/call-work-business-list?type=コール',
    subpath: '/call-work-detail'
  },
  {
    title: 'チャット業務',
    path: '/call-work-business-list?type=チャット'
  },
  {
    title: '遠隔接客業務 ',
    path: '/call-work-business-list?type=遠隔接客'
  }
]

const listMenuB2CUser = [
  {
    title: 'マルチセンタ商材マスタ',
    path: '/dashboard'
  },
  {
    title: 'ヒストリカルレポート',
    path: '/dashboard-history-report'
  },
  {
    title: 'オペレータ成績 ',
    path: '/dashboard-operator-performance'
  },
  {
    title: '商材獲得件数 ',
    path: '/dashboard-products-acquired'
  }
]

const Sidebar = () => {
  const router = useLocation()
  // const splitStr = router?.pathname.split('/')
  // const output = `/${splitStr[1]}`
  const roleUser: any = localStorage.getItem('role-user')
  const queryObj: any = queryString.parse(router.search)
  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={(broken) => {
      }}
      onCollapse={(collapsed, type) => {
      }}
      width={245}
      className='h-[85vh] md:h-[90vh]'
      style={{ background: '#262626' }}
    >
      <FlexCol className='relative h-full' >
        {(JSON.parse(roleUser) === 'b2b' || JSON.parse(roleUser) === 'all') &&
          listMenu?.map((item, index) => (
            <Link
              to={item?.path}
              className="hover:text-white hover:font-bold"
              style={{ borderTop: '1px solid #171717' }}
            >
              <FlexRow
                className={`${router?.pathname === item?.path ? 'is-side-active' : ''
                  }`}
                style={{
                  padding: '15px 30px 15px 30px',
                  width: '226px'
                }}
              >
                <img
                  src={
                    router?.pathname === item?.path
                      ? SideIconBlack
                      : SideIconWhite
                  }
                  alt=""
                />
                <p className="ml-[5px]" style={{ whiteSpace: 'nowrap' }}>
                  {item?.title}
                </p>
              </FlexRow>
            </Link>
          ))}

        {(JSON.parse(roleUser) === 'b2c-admin' || JSON.parse(roleUser) === 'all') && (
          <Menu
            theme="dark"
            className="ant-menu"
            style={{ background: '#262626' }}
            mode="inline"
            defaultSelectedKeys={['1']}
          >
            <SubMenu
              style={{ background: '#262626' }}
              key="3"
              title={
                <FlexRow>
                  <img src={SideIconWhite} alt="" />
                  <p
                    className="ml-[5px]"
                    style={{ whiteSpace: 'nowrap', color: 'white' }}
                  >
                    業務案件テーブル
                  </p>
                </FlexRow>
              }
            >
              {listMenuB2CUser?.map((item, index) => (
                <div style={{ width: '100%', background: '#262626' }}>
                  <Link
                    to={item?.path}
                    className="hover:text-white hover:font-bold"
                    style={{
                      borderTop: '1px solid #171717',
                      background: '#262626'
                    }}
                  >
                    <FlexRow
                      className={`${router?.pathname === item?.path ? 'is-side-active' : ''
                        }`}
                      style={{
                        padding: '15px 30px 15px 30px',
                        width: '226px'
                      }}
                    >
                      <p className="ml-[15px]" style={{ whiteSpace: 'nowrap' }}>
                        {item?.title}
                      </p>
                    </FlexRow>
                  </Link>
                </div>
              ))}
            </SubMenu>
          </Menu>
        )}

        {(JSON.parse(roleUser) === 'b2c-user' || JSON.parse(roleUser) === 'all') &&
          listMenuB2CAdmin?.map((item, index) => (
            <>
              <Link
                to={item?.path}
                className="hover:text-white hover:font-bold"
                style={{ borderTop: '1px solid #171717' }}
              >
                <FlexRow
                  className={`${item?.path.includes(queryObj?.type)
                    ? 'is-side-active'
                    : ''
                    }`}
                  style={{
                    padding: '15px 30px 15px 30px',
                    width: '226px'
                  }}
                >
                  <img
                    src={
                      item?.path.includes(queryObj?.type)
                        ? SideIconBlack
                        : SideIconWhite
                    }
                    alt=""
                  />
                  <p className="ml-[5px]" style={{ whiteSpace: 'nowrap' }}>
                    {item?.title}
                  </p>
                </FlexRow>
              </Link>
            </>
          ))}
        <div className="absolute bottom-0 w-full">
          <hr />
          <FlexCol>
            <p style={{ padding: '15px 30px 15px 30px' }}>R2事業再構築</p>
          </FlexCol>
        </div>
      </FlexCol>

      {/* <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['4']}
          items={[UserOutlined, VideoCameraOutlined, UploadOutlined, UserOutlined].map(
            (icon, index) => ({
              key: String(index + 1),
              icon: React.createElement(icon),
              label: `nav ${index + 1}`,
            }),
          )}
        /> */}
    </Sider>
  )
}

const FlexCol = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px 0px',
  color: '#fff'
})

const FlexRow = styled.div({
  display: 'flex',
  flexDirection: 'row'
})

export default Sidebar
