import { configureStore } from '@reduxjs/toolkit'
import user, { type IUserState } from './slice/user'

const store = configureStore({
  reducer: {
    user: user.reducer
  }
})
export interface RootState {
  user: IUserState
}
export default store
