import React, { Suspense, useEffect, useLayoutEffect } from 'react'
import {
  Route,
  Routes,
  useLocation, // instead of "Switch"
  useNavigate
} from 'react-router-dom'
import './global.scss'
import routes from './routes'
import { Layout, Spin } from 'antd'
import HeaderComp from './components/layout/HeaderComp'
import Sidebar from 'components/layout/Sidebar'
import PrivateRoute from 'components/container/privateRoute/PrivateRoutes'
import PageNotFound from 'pages/PageNotFound'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { useDispatch } from 'react-redux'
import { getCookie } from 'utils/cookie'
import authApi from 'api/authApi'
import userSlice from 'store/slice/user'

function App () {
  const location = useLocation()
  const login: any = localStorage.getItem('login')
  const roleUser: any = localStorage.getItem('role-user')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const user = useSelector((state: RootState) => state.user)

  const getMe = async (token: any) => {
    const newRes = await authApi.getUser(token)
    if (newRes) {
      dispatch(userSlice.actions.storeUser(newRes.data.value))
    }
  }
  useLayoutEffect(() => {
    const token = getCookie('access_token')
    if (token) {
      getMe(token)
    }
  }, [])
  useEffect(() => {
    if (!login || !roleUser) {
      localStorage.removeItem('login')
      localStorage.removeItem('role-user')
      navigate('/login')
    }
  }, [])
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Layout style={{ padding: '0px' }} className='total-screen h-[100vh] lg:max-h-[100vh]' >
        {JSON.parse(login) && location.pathname !== '/login' && <HeaderComp />}
        <Layout hasSider>
          {JSON.parse(login) && location.pathname !== '/login' && <Sidebar />}
          <Suspense fallback={<div className='w-full h-full flex' >
            <Spin size="large" className='m-auto' />
          </div>}>
            <div style={{ overflowY: 'scroll' }} className='w-[100%] xl:w-[85vw] 2xl:w-[100%]' >
              <Routes>
                {routes.map(({ path, element, isProtect, role }) => {
                  const Element = element
                  if (isProtect) {
                    if (role) {
                      role.forEach(item => {
                        if (item === JSON.parse(roleUser)) {
                          return (
                            <Route
                              key={path}
                              path={path}
                              element={
                                <PrivateRoute>
                                  <Element />
                                </PrivateRoute>
                              }
                            />
                          )
                        } else {
                          return <Route key={path} path={path} element={<PageNotFound />} />
                        }
                      })
                    }
                  }

                  return <Route key={path} path={path} element={<Element />} />
                })}
              </Routes>
            </div>
          </Suspense>
        </Layout>
      </Layout>
    </LocalizationProvider>

  )
}

export default App
