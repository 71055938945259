import React from 'react'
import { Layout, Row, Dropdown } from 'antd'
import styled from '@emotion/styled'
// import HomeIcon from 'assets/header/home-icon.svg'
// import MenuIcon from 'assets/header/menu.svg'
// import Category from 'assets/header/category.svg'
// import Setting from 'assets/header/setting.svg'
import Avatar from 'assets/header/header-ava.svg'
import HeaderLogo from 'assets/header/header-logo.svg'
import Bell from 'assets/header/bell.svg'
import ArrowDown from 'assets/header/arr-down.svg'
import type { MenuProps } from 'antd'
import { useNavigate } from 'react-router-dom'

const { Header } = Layout

// const listMenu = [
//   {
//     img: HomeIcon,
//     title: 'ホームページ',
//     path: '/'
//   },
//   {
//     img: MenuIcon,
//     title: 'メニュー',
//     path: '/forecast-received'
//   },
//   {
//     img: Category,
//     title: 'カテゴリー',
//     path: '/category'
//   },
//   {
//     img: Setting,
//     title: '設定',
//     path: '/setting'
//   }
// ]

const HeaderComp = () => {
  const navigate = useNavigate()
  const logout = () => {
    localStorage.removeItem('login')
    localStorage.removeItem('role-user')
    navigate('/login')
  }
  const items: MenuProps['items'] = [
    {
      label: <p onClick={logout} >Logout</p>,
      key: '0'
    }
  ]

  return (
    <HeaderStyle className='h-[15vh] md:h-[10vh]' >
      <FlexRow className='justify-between w-full flex-wrap' >
        <FlexRow>
          {/* {listMenu?.map((item, index) => (
            <Link to={item?.path} className='hover:text-white hover:font-bold'
              style={{ fontWeight: `${item?.path === router.pathname ? 'bold' : ''}` }} >
              <MenuItem key={index} >
                <img src={item?.img} alt="" />
                <p className='px-[5px]' >
                  {item?.title}
                </p>
              </MenuItem>

            </Link>

          ))} */}
          <img src={HeaderLogo} alt='' />
          <p className='text-[24px] leading-[32px] ml-2 mt-[10px]' style={{ whiteSpace: 'nowrap' }}>Exment Data Web</p>
        </FlexRow>

        <FlexRow className='mt-[10px] md:mt-[0px]' >
          <div className='my-auto relative w-[48px] h-[50px] flex' >
            <img src={Bell} alt="" className='my-auto' />
            <RedPart>
              11
            </RedPart>
          </div>
          <Dropdown menu={{ items }} trigger={['click']}>
            <a onClick={(e) => { e.preventDefault() }}>
              <WhitePart className='my-auto' >
                <Row style={{ gap: '15px' }} className='relative' >
                  <img src={Avatar} alt="" />
                  <p className='my-auto' style={{ color: '#111827' }} >m-ishii</p>
                  <img src={ArrowDown} alt="" className='absolute right-0 top-[5px]' />
                </Row>
              </WhitePart>
            </a>
          </Dropdown>

        </FlexRow>

      </FlexRow>

    </HeaderStyle >
  )
}

const FlexRow = styled.div({
  display: 'flex',
  flexDirection: 'row'
})

// const MenuItem = styled.div({
//   margin: '0px 5px',
//   display: 'flex',
//   flexDirection: 'row'
// })

const WhitePart = styled.div({
  background: '#fff',
  width: '177px',
  borderRadius: '36px',
  padding: '3px 15px 3px 3px',
  height: '36px'
})

const RedPart = styled.div({
  background: '#FF4D4F',
  width: 'auto',
  borderRadius: '100px',
  padding: '0px 8px 0px 8px',
  position: 'absolute',
  top: '0px',
  left: '10px',
  fontSize: '12px',
  lineHeight: '20px'
})

const HeaderStyle = styled(Header)({
  display: 'flex',
  alignItems: 'center',
  background: '#171717',
  padding: '0px 30px',
  color: '#fff',
  fontSize: '16px',
  lineHeight: '24px',
  fontFamily: 'Roboto',
  minWidth: '100vw'
})

export default HeaderComp
