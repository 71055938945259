import { Navigate } from 'react-router-dom'
import { getCookie } from 'utils/cookie'

const PrivateRoute = ({ children }: any) => {
//   const {
//     state: { userRole },
//   } = useStore()

  //   if (userRole > 0) {
  //     return children
  //   }

  const isLogin = localStorage.getItem('login')
  const idToken = getCookie('access_token')
  if (isLogin && JSON.parse(isLogin) === true && idToken) {
    return children
  }
  return <Navigate to="/login" />
}

export default PrivateRoute
