import axios, { type InternalAxiosRequestConfig } from 'axios'
import { removeCookie, getCookie } from 'utils/cookie'
// import queryString from 'query-string'

const exmentURL = process.env.REACT_APP_API_URL
const axiosClient = axios.create({
  baseURL: exmentURL,
  headers: {
    'content-type': 'application/json',
    'Device-Type': 'Webapp',
    version: 9999
  }
  // paramsSerializer: (params) => queryString.stringify(params),
})

axiosClient.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const token = getCookie('access_token') || undefined
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  }
)

axiosClient.interceptors.response.use(
  (response: any) => {
    return response
  },
  (error: any) => {
    // Handle errors
    let errorMessage = error
    if (error.response) {
      if (error?.response?.status === 401) {
        removeCookie('access_token')
        localStorage.removeItem('login')
        localStorage.removeItem('role-user')
        window.location.href = '/login'
      }
      errorMessage =
        error.response.data?.message || error.response.data?.messages
    }
    const err = new Error(errorMessage)
    err.name = ''
    throw err
  }
)

export default axiosClient
