import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const PageNotFound = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const roleUser: any = localStorage.getItem('role-user')

  useEffect(() => {
    if (location.pathname === '/') {
      if (JSON.parse(roleUser) === 'b2c-user') {
        navigate('/call-work-business-list')
      } else if (JSON.parse(roleUser) === 'b2c-admin') {
        navigate('/dashboard')
      }
    }
  })
  return (
    <div>Page not found</div>
  )
}

export default PageNotFound
